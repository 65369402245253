/**
 * ## Path Title Slider
 * 
 * Animate the slide in and out of the title screen.
 * 
 * @category Container
 * @module Path Title Slider 
 */
import { useEffect, useRef } from "react";
import { IPathTitleProps } from "../types/types";
import Navigation from "./Navigation";
import { useAppSelector, useAppDispatch } from "../hooks/hooks";
import { setIsOpen } from "../slices/pathTitleSlice"
import { setIsButtonBlocked } from "../slices/buttonSlice";
import SubjectIcon from "../components/SubjectIcon";
import { hideSubjectIcon, showSubjectIcon } from "../slices/subjectIconSlice";
import { showGameButtons, hideGameButtons } from "../slices/miniSlice";
import mini from "../config/mini.json";
import { IMiniPathConfig } from "../types/types";
import { RootState } from "../app/store";
import { ActivityType } from "../components/ActivityUI";
import { ReactComponent as KeepGoingButton } from "../assets/keep-going-button.svg";
import { ReactComponent as PlayButton } from "../assets/play-button.svg";
import { sendMessage } from "../lib/iFrameCommunications";
import { setIsDemoTime } from "../slices/activitySlice";
import config from "../config/config";

export enum SceneType {
    Younger = "younger",
    Older = "older",

    Debug = "debug"
};

export enum CharacterType {
    Autumn = "autumn",
    Charlie = "charlie",
    Jaz = "jaz",
    Levi = "levi",
    Lily = "lily",
    Nestor = "nestor",
    Oba = "oba",
    Socrates = "socrates",
    Violet = "violet",

    Debug = "debug"
};

export enum SubjectType {
    Alphabet = "alphabet",
    ColorsShapes = "colors-shapes",
    Comprehension = "comprehension",
    Counting = "counting",
    Creativity = "creativity",
    EarlyReading = "early-reading",
    Emotions = "emotions",
    MatchingMemory = "matching-memory",
    Math = "math",
    Multiplication = "multiplication",
    MultiplicationDivision = "multiplication-division",
    Numbers = "numbers",
    Phonics = "phonics",
    Puzzles = "puzzles",
    Reading = "reading",
    Rhyming = "rhyming",
    Science = "science",

    Debug = "debug"
};

const PathTitle = (props: IPathTitleProps) => {
    const dispatch = useAppDispatch();
    const pathTitleRef = useRef<HTMLDivElement>(null);
    /**
     * state
     */
    const isNavButtonBlocked = useAppSelector((state) => state.button.isButtonBlocked);
    const parentData = useAppSelector((state) => state.activity.parent);
    const isOpen = useAppSelector((state) => state.title.isOpen);
    const {screenWidth, screenHeight, activityType, skin, newScaleRatio, contentWidth, contentHeight, pathBaseWidth, strokeWidth} = useAppSelector((state: RootState) => state.mini);
    // const {debugSubject} = useAppSelector((state: RootState) => state.mini);
    const hasVideoPlayed = useAppSelector((state) => state.time.hasPlayed);

    const animateDuration = 1000;
    const shadowOpacity = "0.8";
    const arrowButtonSourceWidth = 50;
    const arrowButtonSourceHeight = 160;
    const arrowButtonRenderWidth = Math.trunc(arrowButtonSourceWidth * newScaleRatio);
    const arrowButtonRenderHeight = Math.trunc(arrowButtonSourceHeight * newScaleRatio);

    const miniPathConfig: IMiniPathConfig = mini;
    const isPathFirstVisit = parentData?.settings?.szLearningPathData.pathFirstVisit;

    const handleDemoButton = (e: any) => {
        // Prevent bubble up to 'path-title-field' onClick
        e && e.stopPropagation();
        dispatch(setIsDemoTime(true));
    }

    useEffect(() => {
        if(isPathFirstVisit) {
            requestAnimationFrame(() => {
                dispatch(setIsDemoTime(true));
            });            
        }
    }, [dispatch, isPathFirstVisit]);

    useEffect(() => {
        // Dispatch the action to set isOpen to true on first load
        if(localStorage.getItem(config.LOCAL_STORAGE_KEYS.forced_refresh_next_level) === null) {
            // Normal page load
            pathTitleRef.current!.style.left = "0px";
        }
    }, [dispatch]);

    useEffect(() => {
        const CLOSED_POSITION = -contentWidth + pathBaseWidth + "px";
        const OPEN_POSITION = "0px";
        const startPosition = pathTitleRef.current!.style.left;
        const arrowButton = document.getElementById("title-arrow-button");
        const startArrowRotation = arrowButton!.style.rotate;

        if(isOpen) {
            // Handle open animation
            if(pathTitleRef.current){
                pathTitleRef.current.animate(
                    {
                        left: [startPosition, OPEN_POSITION]
                    },
                    {
                        easing: "ease-in-out",
                        iterations: 1,
                        duration: animateDuration
                    }
                );  
            }  
            pathTitleRef.current!.style.left = OPEN_POSITION;

            // Rotate arrow button
            if(arrowButton !== null){
                arrowButton.animate(
                    {
                        rotate: [startArrowRotation, "180deg"]
                    },
                    {
                        easing: "ease-in-out",
                        iterations: 1,
                        duration: animateDuration
                    }
                );
                arrowButton.style.rotate = "180deg";
            }

            // Reveal shadow
            const shadow = document.getElementById("path-title-shadow");
            if(shadow !== null){
                shadow.animate(
                    {
                        opacity: ["0", shadowOpacity]
                    },
                    {
                        easing: "ease-in-out",
                        iterations: 1,
                        duration: animateDuration
                    }
                );
                shadow.style.opacity = shadowOpacity;
                shadow.style.pointerEvents = "auto"
            }

            // Pause activity or video
            if(window.szGame) {
                window.szGame.game.paused = true;
            }
            if((activityType === ActivityType.Song || activityType === ActivityType.Video) && hasVideoPlayed) {
                const videoPlayer: HTMLVideoElement = document.getElementById("sz-media-player") as HTMLVideoElement;
                videoPlayer.pause();
            }
            
            dispatch(hideSubjectIcon());
        } else {
            // Handle close animation
            if(pathTitleRef.current){
                pathTitleRef.current.animate(
                    {
                        left: [startPosition, CLOSED_POSITION]
                    },
                    {
                        easing: "ease-in-out",
                        iterations: 1,
                        duration: animateDuration
                    }
                );
            }
            pathTitleRef.current!.style.left = CLOSED_POSITION;
    
            // Rotate arrow button
            if(arrowButton !== null) {
                arrowButton.animate(
                    {
                        rotate: [startArrowRotation,"0deg"]
                    },
                    {
                        easing: "ease-in-out",
                        iterations: 1,
                        duration: animateDuration
                    }
                );
                arrowButton.style.rotate = "0deg";
            }
    
            // Hide shadow
            const shadow = document.getElementById("path-title-shadow");
            if(shadow !== null){
                shadow.animate(
                    {
                        opacity: [shadowOpacity, "0"]
                    },
                    {
                        easing: "ease-in-out",
                        iterations: 1,
                        duration: animateDuration
                    }
                );
                shadow.style.opacity = "0";
                shadow.style.pointerEvents = "none"
            }
    
            // Resume activity or video
            if(window.szGame){
                window.szGame.game.paused = false;
            }
            if((activityType === ActivityType.Song || activityType === ActivityType.Video) && hasVideoPlayed){
                const videoPlayer: HTMLVideoElement = document.getElementById("sz-media-player") as HTMLVideoElement;
                videoPlayer.play();
            }
    
            dispatch(showSubjectIcon());
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const titleOpenHandler = () => {
        if (!isOpen) {
            if (document.getElementById("sz-buttons-wrapper")) {
                dispatch(hideGameButtons());
            }
            setTimeout(() => {
                dispatch(setIsOpen(true));
                dispatch(setIsButtonBlocked(true));
            }, 1)
        }
    }

    const titleCloseHandler = () => {
        if (isOpen) {
            dispatch(setIsOpen(false));
            dispatch(setIsButtonBlocked(false));
            setTimeout(() => {
                dispatch(showGameButtons());
            }, animateDuration)
        }    
    }

    /**
     * style the container that will hold all the navigation components
     */
    const numberOfVisibleButtons = 10;
    const navigationPlacementContainerStyle: React.CSSProperties = {
        position: "absolute",
        top: contentHeight * 0.25,
        left: contentWidth - pathBaseWidth, 
        height: arrowButtonRenderHeight * ((numberOfVisibleButtons + 2) / 2),
        width: pathBaseWidth - strokeWidth,
        zIndex: 1102,
        userSelect: "none"
    }

    const demoButtonSourceWidth = 120;
    const demoButtonSourceHeight = 120;
    const titleSourceFontSize = 85;

    const characterRenderWidth = skin.titleCharacterSourceWidth * newScaleRatio;
    const characterRenderHeight = skin.titleCharacterSourceHeight * newScaleRatio;
    const availableTitleWidth = contentWidth - pathBaseWidth;
    const availableTitleHeight = contentHeight;
    const titleAspectRatio = availableTitleWidth / availableTitleHeight;
    const currentIcon = parentData.settings.szLearningPathData.subject;
    const demoButtonRenderHeight = demoButtonSourceHeight * newScaleRatio;

    const handleExitMiniPath = (e: any) => {
        // Prevent bubble up to 'path-title-field' onClick
        e && e.stopPropagation();
        sendMessage("CLOSE MINI");
    }

    

    const createTitleLayout = () => {
        const isWideLayout: boolean = titleAspectRatio > 1.2;

        return (
            <>
                {isWideLayout && <img
                    id="title-character"
                    src={skin.titleCharacter} 
                    style={{
                        position: "absolute",
                        left: 0,
                        top: availableTitleHeight - characterRenderHeight,
                        width: characterRenderWidth,
                        height: characterRenderHeight,
                    }}
                    alt={props.skin.titleCharacterAlt}
                />
                }
                
                <div 
                    id="title-and-icon-container"
                    style={{
                        position: "absolute",
                        top: demoButtonRenderHeight,
                        left: (isWideLayout) ? characterRenderWidth : 0,
                        // backgroundColor: "blue",
                        height: availableTitleHeight - demoButtonRenderHeight,
                        padding: "20px",
                        
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                        alignItems: "center",
                        alignContent: "stretch"
                    }}>

                    <div
                        id="subject-title"
                        style={{
                            color: "000000",
                            fontFamily: "kg_blank_space_solid",
                            fontSize: Math.trunc(titleSourceFontSize * newScaleRatio).toString() + "px",
                            lineHeight: 1.5,

                            display: "block",
                            flexGrow: 2,
                            flexShrink: 1,
                            flexBasis: "auto",
                            alignSelf: "auto",
                            order: 0,
                            //backgroundColor: "blue",
                            minHeight: 0,
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            margin: "auto",
                            textAlign: "center",
                            textAlignLast: "center"
                        }}
                    >
                        {
                            parentData?.settings?.szLearningPathData.pathName
                        }
                    </div>

                    <img
                        id="subject-icon-full"
                        // src={config.icons[debugSubject].imageFull}
                        src={miniPathConfig.icons[props.icon].imageFull}
                        style={{
                            display: "block",
                            flexGrow: 7,
                            flexShrink: 1,
                            flexBasis: "auto",
                            alignSelf: "auto",
                            order: 0,
                            // backgroundColor: "blue",
                            minHeight: 0,
                            padding: "20px"
                        }}
                        alt={miniPathConfig.icons[props.icon].imageFullAlt}                
                    >
                    </img>

                    {isPathFirstVisit ? 
                        <PlayButton style={{
                            display: "block",
                            alignSelf: "auto",
                            order: 0,
                            // backgroundColor: "blue",
                            minHeight: 0,
                            padding: "20px"
                        }}/>
                        : <KeepGoingButton style={{
                            display: "block",
                            alignSelf: "auto",
                            order: 0,
                            // backgroundColor: "blue",
                            minHeight: 0,
                            padding: "20px"
                        }}/>
                    }
                </div>
            </>
        );
    }

    return (
        <>
            <div id="path-title-shadow"
                style={{
                    position: "absolute",
                    top: -(screenHeight - contentHeight) / 2,
                    left: 0,
                    width: screenWidth, //screenWidth * 2,
                    height: screenHeight,
                    background: "#000000",
                    opacity: 0,
                    zIndex: 1102,
                    pointerEvents: "none",
                }}
                    onClick={isOpen ? titleCloseHandler : () => {}}
                >
                    
            </div>

            <div 
                ref={pathTitleRef}
                id="path-title-field"
                style={{
                    position: "absolute",
                    top: 0,
                    left: -(contentWidth - pathBaseWidth),
                    width: contentWidth - pathBaseWidth,
                    height: contentHeight,
                    cursor: "pointer",
                    zIndex: 1102,
                    background: "white"
                }}
                onClick={titleCloseHandler}
            >

                <img
                    id="path-base"
                    src={props.skin.base} 
                    style={{
                        position: "absolute",
                        top: 0,
                        left: contentWidth - pathBaseWidth,
                        maxWidth: "initial",
                        height: contentHeight,
                        zIndex: 1000 
                    }}
                    alt={props.skin.baseAlt}
                />

                <SubjectIcon
                    icon={currentIcon}
                    miniContentWidth={contentWidth}
                    pathWidth={pathBaseWidth}
                    onClick={!isNavButtonBlocked ? titleOpenHandler : () => {}}
                />
                
                <img
                    id="close-button"
                    src="images/close-button.svg" 
                    style={{
                        position: "absolute",
                        left: (demoButtonSourceWidth * newScaleRatio) * 0.5,
                        top: 20,
                        width: demoButtonSourceWidth * newScaleRatio,
                        height: demoButtonSourceHeight * newScaleRatio,
                        zIndex: 1000
                    }}
                    alt="click to close mini path and go back to home page"
                    onClick={handleExitMiniPath}
                />

                <img
                    id="play-demo"
                    src="images/buttons/demo-button.svg" 
                    style={{
                        position: "absolute",
                        left: (demoButtonSourceWidth * newScaleRatio) * 2,
                        top: 20,
                        width: demoButtonSourceWidth * newScaleRatio,
                        height: demoButtonSourceHeight * newScaleRatio,
                        zIndex: 1000 
                    }}
                    alt="click to play video of demo instructions"
                    onClick={handleDemoButton}
                />

                {createTitleLayout()}
            
                <img
                    id="title-arrow-button"
                    style={{
                        position: "absolute",
                        left: contentWidth - arrowButtonRenderWidth - 2,
                        top: Math.trunc((contentHeight / 2) - (arrowButtonRenderHeight / 2)),
                        zIndex: 1103,
                        width: arrowButtonRenderWidth,
                        height: arrowButtonRenderHeight,
                        cursor: "pointer",
                        padding: "4px"
                    }}
                    src="images/buttons/title-arrow.svg"
                    alt="open or close the title menu"
                    onClick={!isNavButtonBlocked ? titleOpenHandler : () => {}}
                />

                <div id="navigation-container" style={navigationPlacementContainerStyle}>
                    <Navigation
                        arrowButtonRenderHeight={arrowButtonRenderHeight}
                    />
                </div>
            </div>
        </>
    )
}

export default PathTitle;
