/**
 * ## AT2X Application Configuration Object
 * 
 * | property | description |
 * | ----- | ----- |
 * | SENTRY_DSN | Sentry DSN for each deployment stage |
 */
interface IConfigObject {
	SENTRY_DSN: { 
		[index: string]: string
	},
	LOCAL_STORAGE_KEYS: { 
		[index: string]: string
	}
}

const config: IConfigObject = {
	SENTRY_DSN: {
		"prod": "https://c77eb1930d9b42b6b2850b9187819ff6@o1103530.ingest.sentry.io/4504624001056768",
		'dev': "https://1305db5518bef24519f228c9a04f1229@o1103530.ingest.us.sentry.io/4504624001056768"
	},

	LOCAL_STORAGE_KEYS: {
		forced_refresh_next_level: "SZ-minipath_newCurrentLevel"
	}
}

export default config;
